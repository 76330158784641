<template>
  <div class="manage-project-sales-legal-process">
    <div class="container fluid">
      <legal-client-detail
        v-if="!_.isEmpty(API)"
        :canManage="false"
        :getClientAPI="API.getClient"
        :markWithLADateAPI="API.markWithLA"
        :markWithoutLADateAPI="API.markWithoutLA"
        :setLADateAPI="API.setLADate"
        :setSPADateAPI="API.setSPADate"
        :signLAAPI="API.signLA"
        :signSPAAPI="API.signSPA"
        :payStampLAAPI="API.payStampLA"
        :payStampSPAAPI="API.payStampSPA"
        :downloadFileAPI="downloadFile"
        :isLawyer="false"
      ></legal-client-detail>
    </div>
  </div>
</template>

<script>
import LegalClientDetail from "@/components/GlobalComponents/Shared/Lawyer/LegalClientDetail";
import { projectLegalProcess as lawyerAPI } from "@/api";

import { downloadFile } from "@/utils/download";

export default {
  components: {
    LegalClientDetail
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      lawyerAPI,
      downloadFile,

      API: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = lawyerAPI.getProjectLawyerAPI(this.isAssigned);
    }
  }
};
</script>

<style lang="scss"></style>
