<template>
  <div class="manage-clients-detail">
    <div class="container fluid">
      <h2 class="mx-2 my-3">Client Details</h2>

      <!-- =================== Legal Doc Detail & Actions ================== -->
      <div class="card p-2 mb-3">
        <div v-if="dataLoaded" class="row">
          <!-- ---------------------------- LA ----------------------------- -->
          <div
            class="col-12 sm-col-6 row align-items-between px-2 mb-3 sm-mb-0"
          >
            <div class="col-12">
              <h4 class="mb-2">Loan Agreement</h4>
              <legal-doc-status
                :status="legalProcess.loanAgreementStatus"
                statusClass="px-4 mb-3"
              ></legal-doc-status>
              <!-- LA Date -->
              <div
                v-if="legalProcess.signLoanAgreementDate"
                class="d-flex justify-content-end"
              >
                <display-data class="text-right mb-3" label="LA Date">
                  {{
                    $moment(legalProcess.signLoanAgreementDate).format(
                      "YYYY-MM-DD"
                    )
                  }}
                </display-data>
              </div>
            </div>
            <!-- Actions -->
            <div
              v-if="canManage"
              class="col-12 d-flex justify-content-end align-items-end"
            >
              <div
                v-if="
                  isLawyer
                    ? legalProcess.loanAgreementLawyer.id == $auth.user().id
                    : true
                "
              >
                <!-- Mortgage Method not selected -->
                <p v-if="!isMortgageMethodSelected()">
                  Client hasn't select a mortgage method
                </p>
                <template v-else>
                  <!-- Mark With/Without LA -->
                  <div
                    v-if="isMarkWithActionsAvailable()"
                    class="text-left sm-text-right"
                  >
                    <p class="mb-1">
                      *The client is not applying bank loan, please confirm
                      whether
                    </p>
                    <fd-button class="main bordered" @click="markAsWithLA()">
                      Will Sign LA
                    </fd-button>
                    <fd-button
                      class="main bordered ml-1"
                      @click="markAsWithoutLA()"
                    >
                      Proceed Without LA
                    </fd-button>
                  </div>
                  <template
                    v-if="
                      isMortgageOtherMethods()
                        ? !isLAPending() && !isWithoutLA()
                        : isLawyerPhase()
                    "
                  >
                    <!-- Set LA Date -->
                    <fd-button
                      v-if="!legalProcess.signLoanAgreementDate"
                      class="main bordered"
                      @click="setDate('LA')"
                    >
                      Set LA Date
                    </fd-button>
                    <!-- Edit LA Date -->
                    <fd-button
                      v-if="
                        legalProcess.signLoanAgreementDate &&
                        legalProcess.loanAgreementStatus !==
                          CLIENT_STATUS.LA_SIGNED &&
                        legalProcess.loanAgreementStatus !==
                          CLIENT_STATUS.LA_STAMP_DUTY_PAID
                      "
                      class="main bordered mr-1"
                      @click="
                        editDate('LA', legalProcess.signLoanAgreementDate)
                      "
                    >
                      Edit LA Date
                    </fd-button>
                    <!-- Sign LA -->
                    <fd-button
                      v-if="
                        legalProcess.signLoanAgreementDate &&
                        legalProcess.loanAgreementStatus ==
                          CLIENT_STATUS.LA_CONFIRMED
                      "
                      class="main bordered"
                      @click="signAgreement('LA')"
                    >
                      LA Signed
                    </fd-button>
                    <!-- Pay Stamp Duty LA -->
                    <fd-button
                      v-if="
                        legalProcess.loanAgreementStatus ==
                        CLIENT_STATUS.LA_SIGNED
                      "
                      class="main bordered"
                      @click="openStampDutyModal('LA')"
                    >
                      LA Stamp Duty Paid
                    </fd-button>
                  </template>
                </template>
              </div>
              <p v-else>Loan Agreement is not assigned to you</p>
            </div>
          </div>
          <!-- ---------------------------- SPA ---------------------------- -->
          <div
            class="col-12 sm-col-6 row align-items-between px-2 mb-3 sm-mb-0"
          >
            <div class="col-12">
              <h4 class="mb-2">Sales Purchase Agreement</h4>
              <legal-doc-status
                :status="legalProcess.salesPurchaseAgreementStatus"
                statusClass="px-4 mb-1"
              ></legal-doc-status>
              <!-- SPA Date -->
              <div
                v-if="legalProcess.signSalesPurchaseAgreementDate"
                class="d-flex justify-content-end"
              >
                <display-data class="text-right mb-3" label="SPA Date">
                  {{
                    $moment(legalProcess.signSalesPurchaseAgreementDate).format(
                      "YYYY-MM-DD"
                    )
                  }}
                </display-data>
              </div>
            </div>
            <!-- Actions -->
            <div v-if="canManage" class="col-12 d-flex justify-content-end">
              <div
                v-if="
                  isLawyer
                    ? legalProcess.salesPurchaseAgreementLawyer.id ==
                      $auth.user().id
                    : true
                "
              >
                <!-- Set SPA Date -->
                <fd-button
                  v-if="!legalProcess.signSalesPurchaseAgreementDate"
                  class="main bordered"
                  @click="setDate('SPA')"
                >
                  Set SPA Date
                </fd-button>
                <!-- Edit SPA Date -->
                <fd-button
                  v-if="
                    legalProcess.signSalesPurchaseAgreementDate &&
                    legalProcess.salesPurchaseAgreementStatus !==
                      CLIENT_STATUS.SPA_SIGNED &&
                    legalProcess.salesPurchaseAgreementStatus !==
                      CLIENT_STATUS.SPA_STAMP_DUTY_PAID
                  "
                  class="main bordered mr-1"
                  @click="
                    editDate('SPA', legalProcess.signSalesPurchaseAgreementDate)
                  "
                >
                  Edit SPA Date
                </fd-button>
                <!-- Sign SPA -->
                <fd-button
                  v-if="
                    legalProcess.signSalesPurchaseAgreementDate &&
                    legalProcess.salesPurchaseAgreementStatus ==
                      CLIENT_STATUS.SPA_CONFIRMED
                  "
                  class="main bordered"
                  @click="signAgreement('SPA')"
                >
                  SPA Signed
                </fd-button>
                <!-- Pay Stamp Duty SPA -->
                <fd-button
                  v-if="
                    legalProcess.salesPurchaseAgreementStatus ==
                    CLIENT_STATUS.SPA_SIGNED
                  "
                  class="main bordered"
                  @click="openStampDutyModal('SPA')"
                >
                  SPA Stamp Duty Paid
                </fd-button>
              </div>
              <p v-else>Sales Purchase Agreement is not assigned to you.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- ========================= Buyer Detail ========================== -->
      <div class="card p-2 mb-3">
        <h4 class="mx-2 my-2">Buyer Detail</h4>

        <div v-if="dataLoaded" class="bordered-card row p-2">
          <!-- Buyer contact -->
          <display-data :class="displayDataCSS" label="Buyer Email">
            {{ client.projectPurchaseReservation.email }}
          </display-data>
          <display-data :class="displayDataCSS" label="Buyer Contact No.">
            {{ client.projectPurchaseReservation.phoneContact }}
          </display-data>
          <!-- Buyer info -->
          <div class="col-12 row">
            <display-data :class="displayDataCSS" label="Buyer Name">
              {{ client.projectPurchaseReservation.purchaserName1 }}
            </display-data>
            <display-data :class="displayDataCSS" label="Gender">
              {{ client.projectPurchaseReservation.gender1 }}
            </display-data>
            <display-data
              v-if="client.projectPurchaseReservation.purchaserName2"
              :class="displayDataCSS"
              label="Buyer 2 Name"
            >
              {{ client.projectPurchaseReservation.purchaserName2 }}
            </display-data>
            <display-data
              v-if="client.projectPurchaseReservation.gender2"
              :class="displayDataCSS"
              label="Buyer 2 Gender"
            >
              {{ client.projectPurchaseReservation.gender2 }}
            </display-data>
          </div>
        </div>
      </div>
      <!-- ========================= Agent Detail ========================= -->
      <agent-info
        v-if="client.agent"
        class="card p-3 mb-3"
        :agencyUser="client.agent"
      ></agent-info>

      <!-- ========================== Documents ============================ -->
      <div class="card p-2 mb-3">
        <!-- Documents -->
        <h4 class="mx-2 my-2">Documents</h4>
        <!-- Download IC & Status -->
        <download-button
          label="IC/Passport"
          :downloadItem="booking.purchaserIdentityCardOrPassport"
          :filenamePrefix="`project_booking_ic_${client.id}`"
          @download="downloadFile"
        >
        </download-button>
        <!-- Download Saleform & Status -->
        <download-button
          label="Sale Form"
          :downloadItem="booking.saleForm"
          :filenamePrefix="`project_booking_saleform_${client.id}`"
          @download="downloadFile"
        >
        </download-button>
        <!-- Download Receipt & Status -->
        <download-button
          label="Payment Receipt"
          :downloadItem="booking.paymentReceipt"
          :filenamePrefix="`project_booking_receipt_${client.id}`"
          @download="downloadFile"
        >
        </download-button>
      </div>
      <!-- ========================== Bank Loan ============================ -->
      <div v-if="isBankLoanInfoAvailable()" class="card p-2 mb-3">
        <h4 class="mx-2 my-3">Bank Loan Info</h4>

        <div class="final-loan-amount mx-2 mb-2">
          <span class="d-block label">Final Loan Amount</span>
          RM {{ bankLoan.finalLoanAmount }}
        </div>

        <div v-if="banker && dataLoaded" class="row p-2">
          <!-- Buyer contact -->
          <display-data
            :class="displayDataCSS"
            label="Banker Name"
            :content="banker.name"
          >
          </display-data>
          <display-data
            :class="displayDataCSS"
            label="Bank"
            :content="banker.bank"
          >
          </display-data>
          <display-data
            :class="displayDataCSS"
            label="Banker Email"
            :content="banker.user.email"
          >
          </display-data>
          <display-data
            :class="displayDataCSS"
            label="Banker Contact"
            :content="$getUserPhoneNumber(banker.user)"
          >
          </display-data>
        </div>
      </div>
    </div>
    <!-- ====================== MODAL: Set LA/SPA Date ===================== -->
    <modal v-model="setDateModal.isShown" persistent>
      <set-date
        :isEdit="setDateModal.isEdit"
        :type="setDateModal.type"
        :date="setDateModal.date"
        :maxDate="legalProcess.salesPurchaseAgreementDueDate"
        @cancel="setDateModal.isShown = false"
        @submit="updateAgreementDate"
      ></set-date>
    </modal>
    <!-- ====================== MODAL: Stamp Duty Paid ===================== -->
    <modal v-model="stampDutyModal.isShown" persistent>
      <confirm-stamp-duty
        :type="stampDutyModal.type"
        @cancel="stampDutyModal.isShown = false"
        @submit="payStampDuty"
      ></confirm-stamp-duty>
    </modal>
  </div>
</template>

<script>
import { projectLawyerClientStatus as CLIENT_STATUS } from "@/enums";
import { getFileExtension } from "@/utils/string";
import _isEmpty from "lodash/isEmpty";

export default {
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    AgentInfo: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/General/AgentInfo"
      ),
    LegalDocStatus: () =>
      import("@/components/GlobalComponents/Shared/Lawyer/LegalDocStatus"),
    DownloadButton: () =>
      import(
        "@/components/GlobalComponents/Shared/Lawyer/LegalDocDownloadButton"
      ),
    SetDate: () =>
      import("@/components/GlobalComponents/Shared/Lawyer/LegalDocSetDate"),
    ConfirmStampDuty: () =>
      import(
        "@/components/GlobalComponents/Shared/Lawyer/LegalDocConfirmStampDuty"
      )
  },
  mixins: [],
  props: {
    canManage: {
      type: Boolean,
      default: true
    },
    getClientAPI: {
      type: Function,
      required: true
    },
    setLADateAPI: {
      type: Function,
      required: true
    },
    setSPADateAPI: {
      type: Function,
      required: true
    },
    signLAAPI: {
      type: Function,
      required: true
    },
    signSPAAPI: {
      type: Function,
      required: true
    },
    payStampLAAPI: {
      type: Function,
      required: true
    },
    payStampSPAAPI: {
      type: Function,
      required: true
    },
    markWithLADateAPI: {
      type: Function,
      required: true
    },
    markWithoutLADateAPI: {
      type: Function,
      required: true
    },
    downloadFileAPI: {
      type: Function,
      required: true
    },
    isLawyer: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      client: {},
      legalProcess: {},
      booking: {
        purchaserIdentityCardOrPassport: [],
        saleForm: [],
        paymentReceipt: []
      },
      bankLoan: {},
      banker: {},
      mortgage: {},
      dataLoaded: false,

      setDateModal: {
        isShown: false,
        type: "",
        isEdit: false,
        date: ""
      },
      stampDutyModal: {
        isShown: false,
        type: "",
        isEdit: false,
        date: ""
      },

      displayDataCSS: "col-12 sm-col-6 md-col-4 lg-col-3",
      CLIENT_STATUS
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getClientData();
        this.dataLoaded = true;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    setDate(type) {
      this.setDateModal.type = type;
      this.setDateModal.isEdit = false;
      this.setDateModal.isShown = true;
    },
    editDate(type, date) {
      this.$nextTick(() => {
        this.setDateModal.type = type;
        this.setDateModal.date = date;
        this.setDateModal.isEdit = true;
        this.setDateModal.isShown = true;
      });
    },
    openStampDutyModal(type) {
      this.stampDutyModal.type = type;
      this.stampDutyModal.isShown = true;
    },
    isBankLoanInfoAvailable() {
      let LAStatus = this.legalProcess.loanAgreementStatus;
      return (
        (LAStatus == CLIENT_STATUS.LA_CONFIRMED ||
          LAStatus == CLIENT_STATUS.LA_STAMP_DUTY_PAID) &&
        !_isEmpty(this.bankLoan)
      );
    },
    isMortgageMethodSelected() {
      return this.mortgage.mortgageMethods.length > 0;
    },
    isLAPending() {
      let LAStatus = this.legalProcess.loanAgreementStatus;
      return LAStatus == CLIENT_STATUS.LA_PENDING;
    },
    isWithoutLA() {
      let LAStatus = this.legalProcess.loanAgreementStatus;
      return LAStatus == CLIENT_STATUS.LA_WITHOUT_LA;
    },
    isMortgageOtherMethods() {
      return this.banker == null && this.isLawyerPhase();
    },
    isLawyerPhase() {
      return this.client.currentPhase === "Lawyer";
    },
    isMarkWithActionsAvailable() {
      return this.isMortgageOtherMethods() && this.isLAPending();
    },
    // ============================== API RELATED ==============================
    async getClientData() {
      try {
        let data = await this.getClientAPI(this.$route.params.id);
        this.client = this._.cloneDeep(data);
        this.legalProcess = this._.cloneDeep(data.projectPurchaseLawyer);
        this.booking = this._.cloneDeep(data.projectPurchaseBooking);
        this.bankLoan =
          data.projectPurchaseMortgage.mortgageMethods.length > 0
            ? this._.cloneDeep(
                data.projectPurchaseMortgage.mortgageMethods[0].bankLoans[0]
              )
            : {};
        this.banker = this._.cloneDeep(
          data.projectPurchaseLawyer.selectedBanker
        );
        this.mortgage = this._.cloneDeep(data.projectPurchaseMortgage);
      } catch (error) {
        console.log(error);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get client data. Please try again later."
        });
        throw error;
      }
    },
    async downloadFile(file, label, loading) {
      loading(true);
      let url = this.$getFileURL(file);
      let ext = getFileExtension(file.fileName);

      await this.downloadFileAPI(url, label, ext);
      loading(false);
    },
    async markAsWithLA() {
      let confirm = await this.$confirm({
        title: "Confirm LA Needed",
        message: `Are you sure Loan Agreement is needed for this purchase?`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.markWithLADateAPI(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Loan Agreement status has been updated."
          });
          await this.getClientData();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to confirm client will sign LA. Please try again later."
          });
        }
      }
    },
    async markAsWithoutLA() {
      let confirm = await this.$confirm({
        title: "Proceed Without LA",
        message: `Are you sure? Loan Agreement will marked as not needed so the purchase can be completed with just SPA.`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.markWithoutLADateAPI(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Loan Agreement status has been updated."
          });
          await this.getClientData();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to mark as without LA. Please try again later."
          });
        }
      }
    },
    async updateAgreementDate(type, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        if (type == "LA") {
          await this.setLADateAPI(this.$route.params.id, payload);
        } else if (type == "SPA") {
          await this.setSPADateAPI(this.$route.params.id, payload);
        }
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: `${type} date has been updated successfully.`
        });

        this.setDateModal.isShown = false;
        await this.getClientData();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Failed to update ${type} date. Please try again later.`
        });
      }
    },
    async signAgreement(type) {
      let confirm = await this.$confirm({
        title: `Confirm ${type} as Signed`,
        message: `Are you sure ${type} is signed? This action cannot be undone.`,
        confirmText: "Confirm"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);

          if (type == "LA") {
            await this.signLAAPI(this.$route.params.id);
          } else if (type == "SPA") {
            await this.signSPAAPI(this.$route.params.id);
          }
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `${type} has been confirmed as signed.`
          });

          await this.getClientData();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: `Failed to confirm ${type} as signed. Please try again later.`
          });
        }
      }
    },
    async payStampDuty(type, payload) {
      try {
        this.$store.commit("setIsLoading", true);

        if (type == "LA") {
          await this.payStampLAAPI(this.$route.params.id, payload);
        } else if (type == "SPA") {
          await this.payStampSPAAPI(this.$route.params.id, payload);
        }

        this.stampDutyModal.isShown = false;

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: `Stamp duty of ${type} has been confirmed paid .`
        });

        await this.getClientData();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: `Failed to confirm ${type} stamp duty as paid. Please try again later.`
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-clients-detail {
  .final-loan-amount {
    .label {
      color: $color-accent2;
      font-size: 12px;
      text-transform: uppercase;
    }
    font-size: 22px;
    font-weight: bold;
    color: $color-accent2;
    border-radius: 4px;
    padding: 12px 16px;
    width: fit-content;
    box-shadow: 0px 2px 5px #00000030;
  }
}
</style>
